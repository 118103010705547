import { getDonors, getMedia, getMyCharity } from "../services/apiActions";

import { getShareSendersEnabled } from "../services/apiActions/senders";
import {
  getAutomationStatus,
  getMessageThreads,
} from "../services/apiActions/charities";
import Content from "../components/Content";
import Footer from "../components/Footer";
// import { LANDING } from "../routes/constants";
import { AUTH_ROUTES } from "../routes/constants";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import { PUBLIC_ROLES } from "../roleConstants";
import React from "react";
import { Redirect } from "react-router";
import Sidebar from "../components/Sidebar";
import Wrapper from "../components/Wrapper";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    if (
      this.props.userRoles.filter(
        (role) =>
          Object.values(PUBLIC_ROLES).includes(role) &&
          role !== PUBLIC_ROLES.REPORT_ADMINISTRATOR
      ).length > 0
    ) {
      getMedia();
      getMyCharity();
    }
    if (
      this.props.userRoles.includes(PUBLIC_ROLES.ADMINISTRATOR) ||
      this.props.userRoles.includes(PUBLIC_ROLES.CONTENT_REVIEWER) ||
      this.props.userRoles.includes(
        PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR
      )
    ) {
      //getDonations();
      getDonors();
      getShareSendersEnabled();
      getMessageThreads();
      getAutomationStatus();
    }
  }

  render() {
    if (!this.props.apiKey) {
      return <Redirect to={AUTH_ROUTES.SIGN_IN} />;
    }

    return (
      <React.Fragment>
        <Wrapper>
          <Sidebar />
          <Main>
            <Navbar />
            <Content>{this.props.children}</Content>
            <Footer />
          </Main>
        </Wrapper>
      </React.Fragment>
    );
  }
}

export default connect((store) => ({
  userRoles: store.user.userRoles,
  twelveMonthShares: store.dashboard.twelveMonthShares,
  twelveMonthViews: store.dashboard.twelveMonthViews,
  apiKey: store.user.apiKey,
}))(Dashboard);
