import { sendRequest } from "../apiService";

export function getGroupAdministratorCampaignMetrics(
  startDate,
  endDate,
  callback
) {
  sendRequest({
    request: {
      resource: `/group-administrators/me/campaigns/${startDate}/${endDate}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getGroupAdministratorOrganizations(callback) {
  sendRequest({
    request: {
      resource: `/group-administrators/me/organizations`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCampaignDetails(campaignId, callback) {
  sendRequest({
    request: {
      resource: "/group-administrators/me/campaigns/:campaignId".replace(
        ":campaignId",
        campaignId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getOrganizationAdministrator(callback) {
  sendRequest({
    request: {
      resource: `/sessions/me/profile`,
      method: "get",
    },
    success: (response) => {
      callback(response);
    },
  });
}

export function getOrganizationAdministratorOrganizations(
  callback,
  failureCallback
) {
  sendRequest({
    request: {
      resource: `/sessions/me/organizations`,
      method: "get",
    },
    success: (response) => {
      callback(response);
    },
    failure: (error) => {
      if (failureCallback) {
        failureCallback(error);
      }
    },
  });
}

export function changeCurrentOrganization(orgId, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/sessions/me/organizations`,
      method: "put",
    },
    data: { organizationId: orgId },
    success: (response) => {
      callback(response);
    },
    failure: (error) => {
      if (failureCallback) {
        failureCallback(error);
      }
    },
  });
}

export function changeDefaultOrganization(orgId, callback) {
  sendRequest({
    request: {
      resource: `/sessions/me/organizations/default`,
      method: "put",
    },
    data: { organizationId: orgId },
    success: (response) => {
      callback(response);
    },
  });
}
