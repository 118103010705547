import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { Settings } from "react-feather";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import {
  debugBecomeCharityAdmin,
  debugBecomeContentCreator,
  debugBecomeContentManager,
  debugBecomeSystemAdmin,
  signOut,
} from "../redux/actions/userActions";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { PUBLIC_ROLES } from "../roleConstants";
import {
  getGroupAdministratorOrganizations,
  changeCurrentOrganization,
} from "../services/apiActions/group-admin";

const getUserRolesFromCookie = () => {
  const token = Cookies.get("apiKey");
  if (!token) return [];
  const decoded = jwtDecode(token);
  const roleKey =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  const roles = decoded && decoded[roleKey] ? decoded[roleKey] : [];
  return Array.isArray(roles) ? roles : [roles];
};

const getCurrentOrganizationFromCookie = () => {
  const token = Cookies.get("apiKey");
  if (!token) return null;
  const decoded = jwtDecode(token);
  return decoded["current_organization_id"];
};

function getOrganizations(callback) {
  getGroupAdministratorOrganizations((response) => {
    const currentOrganizationId = getCurrentOrganizationFromCookie();
    const organizations = response.data
      .filter((org) => org.isSelected)
      .filter((org) => org.id !== currentOrganizationId)
      .map((org) => ({
        id: org.id,
        name: org.name,
      }));
    callback(organizations);
  });
}

function NavbarComponent({
  dispatch,
  toggleSidebar,
  signOut,
  debugBecomeCharityAdmin,
  debugBecomeSystemAdmin,
  debugBecomeContentCreator,
  debugBecomeContentManager,
  userName,
}) {
  const roles = getUserRolesFromCookie();
  const userHasSwitchOrgRole = roles.includes(
    PUBLIC_ROLES.MULTI_ORGANIZATION_ADMINISTRATOR
  );

  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    if (userHasSwitchOrgRole) {
      getOrganizations((fetchedOrgs) => {
        setOrgs(fetchedOrgs);
      });
    }
  }, [userHasSwitchOrgRole]);

  const handleOrganizationSwitch = (orgId) => {
    changeCurrentOrganization(orgId, (response) => {
      Cookies.set("apiKey", response.data);
      window.location.reload();
    });
  };

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          toggleSidebar();
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{userName}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              {process.env.NODE_ENV === "development" && (
                <>
                  <DropdownItem onClick={() => debugBecomeCharityAdmin()}>
                    Change to Charity Admin Role
                  </DropdownItem>
                  <DropdownItem onClick={() => debugBecomeContentManager()}>
                    Change to Content Manager Role
                  </DropdownItem>
                  <DropdownItem onClick={() => debugBecomeContentCreator()}>
                    Change to Content Creator Role
                  </DropdownItem>
                </>
              )}

              {userHasSwitchOrgRole && (
                <>
                  <DropdownItem divider />
                  <DropdownItem header>Switch Organization</DropdownItem>
                  {orgs.map((org) => (
                    <DropdownItem
                      key={org.id}
                      onClick={() => handleOrganizationSwitch(org.id)}
                    >
                      {org.name}
                    </DropdownItem>
                  ))}
                  <DropdownItem divider />
                </>
              )}

              <DropdownItem href="/auth/change-password">
                Change Password
              </DropdownItem>
              <DropdownItem onClick={signOut}>Sign out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default connect(
  (store) => ({
    userName: store.user.userName,
  }),
  (dispatch) => ({
    signOut: () => {
      dispatch(signOut());
    },
    toggleSidebar: () => {
      dispatch(toggleSidebar());
    },
    debugBecomeCharityAdmin: () => {
      dispatch(debugBecomeCharityAdmin());
    },
    debugBecomeSystemAdmin: () => {
      dispatch(debugBecomeSystemAdmin());
    },
    debugBecomeContentManager: () => {
      dispatch(debugBecomeContentManager());
    },
    debugBecomeContentCreator: () => {
      dispatch(debugBecomeContentCreator());
    },
  })
)(NavbarComponent);
